<template>
  <div>
    <Breadcrumbs primary="Системные отчеты" secondary="Журнал событий"/>

    <form style="display: none" method="post" id="exportForm"
          :action="`${axios.defaults.baseURL}/reports/downloadEventLogReport`">
      <input name="params" type="hidden" id="exportParams"/>
      <input name="__sg_client_token" type="hidden" :value="$store.getters.token"/>
    </form>
    <el-row>
      <el-col :span="24">
        <el-card class="box-card no-top-body-padding no-border">
          <el-row :gutter="40">
            <el-col :md="12" :xs="24">

              <TimeSearch
                  :full-width-select="true"
                  ref="timeSearch"
                  :short-date="true"
                  :month="true"
                  :free-date-time="true"
              />

            </el-col>
            <el-col :md="12" :xs="24">
              <el-form label-position="top" style="width: 100%">
                <el-form-item label="Рассылка" >
                  <el-select v-model="searchTask" class="full-width" filterable>
                    <el-option label="Любая" value=""></el-option>
                    <el-option
                        v-for="(task, key) in tasks"
                        :key="key"
                        :label="task.name"
                        :value="task._id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Номер телефона">
                  <el-input v-model="searchPhone"></el-input>
                </el-form-item>
                <el-form-item label="Поиск по событию">
                  <el-select v-model="searchToken" class="full-width">
                    <el-option label="Все" value="" />
                    <el-option
                        v-for="status in errorMessages"
                        :label="status" :value="status"
                    />

                  </el-select>
<!--                  <el-input v-model="searchToken"></el-input>-->
                  <el-checkbox v-model="excludeToken" label="Не содержит данного значения"></el-checkbox>
                </el-form-item>

              </el-form>
            </el-col>
          </el-row>
          <el-button type="success" plain @click="getReport()" :loading="reportLoading" style="margin-top: 15px">Применить</el-button>
          <el-row v-show="reportReady">
            <el-col :span="24">

              <TableSpinner :loading="dataLoading" style="margin-top: 50px">
                <div style="display: flex;align-items: center;justify-content: space-between; margin-bottom : 40px">
                  <span style="color: var(--el-text-color-regular)">Количество событий: {{ totalEvents }}</span>
                  <el-button text class="text-button" @click="exportReportData"
                             style="padding: 0px; min-height: auto;height: auto !important">
                    <font-awesome-icon icon="external-link-alt"/>&nbsp;Экспортировать CSV файл
                  </el-button>
                </div>

                <el-table :data="allEvents" style="width: 100%" stripe>
                  <el-table-column prop="user_id" label="Пользователь" min-width="130"/>
                  <el-table-column prop="phone" label="Номер телефона" min-width="150"/>
                  <el-table-column prop="sender" label="Подпись" min-width="150"/>
                  <el-table-column prop="channel" label="Тип сообщения" min-width="150" :formatter="messageType"/>
                  <el-table-column prop="event" label="Событие" min-width="180"/>
                  <el-table-column :formatter="dateTime" label="Дата и время" min-width="150"/>
                </el-table>
                <div class="solo-pagination-container">
                  <el-pagination
                      background
                      class="wide-page-size"
                      layout="sizes, prev, pager, next"
                      :total="totalEvents"
                      :pager-count="3"
                      :page-sizes="[10, 20, 50, 100]"
                      :page-size="pageSize"
                      :hide-on-single-page="true"
                      @size-change="handlePageSizeChange"
                      @current-change="handleCurrentPageChange"
                  >
                  </el-pagination>
                </div>
              </TableSpinner>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>

  </div>
</template>

<script>


import TimeSearch from "@shared/components/reports/time-search.component"


import moment from "moment";

export default {
  name: "EventLog",
  title: "Журнал событий",
  components: {TimeSearch},
  methods: {
    dateTime(row) {
      return moment(row.date).utcOffset(this.$store.getters.user.utcOffset || 0).format("DD.MM.YYYY HH:mm:ss")
    },
    messageType(row){
      switch(row.channel){
        case "sms":
          return "СМС"
        case "vk":
          return "ВК/ОК"
        case "call":
          return CALL_TYPES[row.callType] || ""
        case "telegram":
          return "Telegram"
        default:
          return ""
      }
    },
    handlePageSizeChange(newSize) {
      this.pageSize = newSize
      this.getReport(false)
    },
    handleCurrentPageChange(newPage) {
      this.currentPage = newPage
      this.getReport(false)
    },
    __getReportParams() {
      let dateFilter = this.$refs.timeSearch.getDateTimeFilter()
      return {
        date_from: dateFilter.from.format("DD.MM.YYYY HH:mm"),
        date_to: dateFilter.to.format("DD.MM.YYYY HH:mm"),
        searchToken: this.searchToken,
        searchPhone: this.searchPhone,
        excludeToken: this.excludeToken,
        pageNumber: this.currentPage,
        pageSize: this.pageSize,
        taskId: this.searchTask
      }
    },
    exportReportData() {
      document.getElementById("exportParams").value = JSON.stringify(this.__getReportParams())
      document.getElementById("exportForm").submit()
    },
    getReport(doCount = true) {
      if (doCount) {
        this.reportLoading = true
        this.reportReady = false
      } else {
        this.dataLoading = true
      }
      let params = this.__getReportParams()
      let p1 = this.axios.get("/reports/eventLogReport", {params})
          .then(resp => {
            if (doCount) {
              this.reportLoading = false
              this.reportReady = true
            }
            this.allEvents = resp.data.result
          })
      let toWait = [p1]
      if (doCount) {
        params.justCount = true
        let p2 = this.axios.get("/reports/eventLogReport", {params})
            .then(resp => {
              this.totalEvents = resp.data.result
            })
        toWait.push(p2)
      }
      Promise.all(toWait).then(() => this.dataLoading = false)
    }
  },
  computed:{
    tasks(){
      return this.$store.getters.allTasks
    }
  },
  data() {
    return {
      reportReady: false,
      reportLoading: false,
      dataLoading: false,
      searchCategory: "",
      searchTask: "",
      searchToken: "",
      searchPhone: "",
      tableSearchToken: "",
      excludeToken: false,
      totalEvents: 0,
      pageSize: 10,
      currentPage: 1,
      allEvents: [],
      errorMessages: [
        "Данная подпись отправителя не подтверждена",
        "Отправка заблокирована, номер телефона находится в черном списке",
        "Некорректный номер телефона , сообщение на этот номер не отправлено",
        "Слишком частая отправка одинаковых сообщений на один номер",
        "Превышен лимит отправки сообщений 1 абоненту в сутки",
        "Превышен суточный лимит затрат на отправку сообщений",
        "Не достаточно средств на счету",
        "Длинна сообщения больше 480 символов",
        "Текст международной СМС не подходит для конвертации",
        "Отправка международных сообщений закрыта",
        "Сообщение не прошло модерацию",
        "Сообщение не прошло ручную модерацию",
        "Сообщение отправлено на ручную модерацию",

        "Данное направление не подключено",
        "Telegram бот не активен",
        "Telegram контакт не активен",
        "Сообщение не прошло тарификацию",
        "В тестовом доступе разрешена отправка только на свой номер, подтвержденный при регистрации",
        "В тестовом доступе разрешены звонки только на свой номер, подтвержденный при регистрации",
        "Счёт заблокирован"
      ]
    }
  },
}

</script>